/* Reset */
@import 'tailwindcss/base';
@import 'reset';

/* Components */
@import 'tailwindcss/components';
@import 'buttons';
@import 'form';

/* Utilities */
@import 'tailwindcss/utilities';
